<template>
    <div class="clientcabin clientcabin-reasons">
        <div class="blueish">
            <v-container>
                <v-row justify="center">
                    <v-col cols="12" lg="9" xl="7" class="pt-10 pb-6">
                        <p class="text-h4 font-weight-medium">
                            Results & Reasons
                        </p>
                        <p>
                            See why {{ company }} can grow your business and the
                            results you can get with our team
                        </p>
                    </v-col>
                </v-row>
            </v-container>
        </div>
        <v-container>
            <v-row justify="center">
                <v-col cols="12" lg="9" xl="7" class="py-6 py-md-10">
                    <v-card class="mx-auto" to="/reasons/megaphone-effect">
                        <v-img
                            src="@/assets/img/clientcabin/reasons/boost-your-brand/1.png"
                            height="300px"
                        />

                        <v-card-title>
                            <div class="primary--text mt-2">
                                The
                                {{ company }} Effect: That Gets Your Brand Heard
                                By A Massive Audience
                            </div>
                        </v-card-title>

                        <v-card-subtitle class="mt-0 text-body-2 black--text">
                            {{ company }} helps brands get more famous and
                            trustworthy by sharing their content on big
                            websites, podcasts, image directories, video
                            platforms, and influential blogs that lots of people
                            follow.
                            <div class="primary--text mt-2 text-subtitle-1">
                                Read More
                            </div>
                        </v-card-subtitle>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <div class="blueish">
            <v-container>
                <v-row justify="center">
                    <v-col cols="12" lg="9" xl="7" class="pt-10 pb-6">
                        <p class="text-h4 font-weight-medium">
                            <b>{{ company }}</b>
                            Customer Stories
                        </p>
                    </v-col>
                </v-row>
            </v-container>
        </div>
        <v-container>
            <v-row justify="center">
                <v-col cols="12" lg="9" xl="7" class="py-6 py-md-10">
                    <v-card
                        class="mx-auto"
                        to="/reasons/successful-wine-marketing-example"
                    >
                        <v-img
                            src="@/assets/img/clientcabin/stories/successful-wine-marketing-example/1.png"
                            max-height="300"
                            position="top center"
                        />

                        <v-card-title>
                            <div class="primary--text mt-2">
                                Successful Wine Marketing Example
                                <br />
                                Content Plan Strategy Grows Blog 1100% in 21
                                Months
                            </div>
                        </v-card-title>

                        <v-card-subtitle class="mt-0 text-body-2 black--text">
                            While every business is different, the results
                            achieved by the winery store demonstrate the
                            potential of content marketing and the power of our
                            content amplification technique.
                            <div class="primary--text mt-2 text-subtitle-1">
                                Read More
                            </div>
                        </v-card-subtitle>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

import { InjectReactive } from '@/utils/decorators';

import type { ClientCabin } from '@/types/ClientCabin';

@Component
export default class Reasons extends Vue {
    @InjectReactive({
        from: 'options',
        default() {
            return null;
        }
    })
    options!: Partial<ClientCabin> | null;

    get company() {
        return this.options?.business_name || 'Our Company';
    }
}
</script>

<style lang="scss" scoped>
.clientcabin-reasons::v-deep {
    background-color: $white;
    color: $black;
}
</style>
